import GatsbyImage from "gatsby-image"
import styled from "styled-components"
import colors from "@styles/colors"

const Image = styled(GatsbyImage)``

export default Image

export const DocumentationImage = styled(Image)`
  max-width: 100%;
  box-shadow: 0 2px 8px rgba(27, 39, 51, 0.05);
  border: 2px solid ${colors.gray.normal};
  margin-bottom: 1.5rem;
`

export const Thumbnail = styled(Image)`
  max-width: 100%ƒ;
  margin-bottom: 1.5rem;
  box-shadow: rgba(84, 70, 35, 0.15) 0px 2px 8px,
    rgba(84, 70, 35, 0.15) 0px 1px 3px;
  border-radius: 4px;
`
